<template>
  <div class="sortingInfo">
    <div class="breadcrumb_box">
      <Breadcrumb>
        <BreadcrumbItem v-for="(item, index) in breadcrumbList" :key="index" :to="item.path ? item.path : ''">{{ item.name }}</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <!-- 当前拣货单 -->
    <div class="pickHeader">
      <Row>
        <Col span="22" style="display: flex; align-items: center;">
          <div style="fontSize:16px;marginLeft:10px;fontWeigh:bold;">
            {{ $t('key1003670') }}{{ showNo }} {{ $t('key1000178') }}
          </div>
          <div style="fontSize:16px;marginLeft:10px;fontWeigh:bold; margin-left: 30px;">
            {{ $t('key1003374') }}{{ labelTypeText }}
          </div>
        </Col>
        <Col span="2">
          <Button size="small" style="marginLeft:30px;" @click="pickFinish">{{ $t('key1003671') }} </Button>
        </Col>
      </Row>
    </div>
    <!-- content -->
    <div class="">
      <Row>
        <Col span="16">
          <!-- 输入sku开始分拣 -->
          <div class="skuInput">
            {{ $t('key1003672') }} <Input
            ref="curSku" style="width:300px;marginLeft:15px;" autofocus @on-enter="clearSku();skuStart()"></Input>
          </div>
          <!-- 找篮子及展示货品信息 -->
          <div class="showProductGoods">
            <div v-show="showProductGoods && sku !== null">
              <Row>
                <Col span="15">
                  <!-- 拣货框 -->
                  <div style="marginLeft:60%;paddingTop:10px;paddingBottom:20px;">
                    <div :style="curBox.color" class="productBox">
                      <span style="fontSize:50px;">{{ curBox.boxNo }}</span>
                    </div>
                  </div>
                </Col>
                <Col span="7">
                  <!-- 全部分拣完成 -->
                  <div
                    v-if="isFinishStatus"
                    style="marginLeft:10%;paddingTop:20px;paddingBottom:20px;width:300px;height:200px;">
                    <div>
                      <Row style="marginBottom:20px;">
                        <Col span="6">
                          <div>
                            <Icon
                              type="md-information-circle"
                              color="#169bd5"
                              style="font-size:40px;marginLeft:20px;lineHeight:20px;"></Icon>
                          </div>
                        </Col>
                        <Col>
                          <h2 style="color:#ff0000;">
                            {{ $t('key1003673') }} </h2>
                        </Col>
                      </Row>
                      <span style="font-size:15px;font-weight:bold;color:#ff0000;">{{
                          $t('key1003668').replace(
                            '{pickingGoodsNo}',
                            showNo
                          )
                        }}</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <!-- 货品信息 -->
              <div class="productInfo">
                <Row>
                  <Col span="10">
                    <div style="width:300px;height:170px;">
                      <Tooltip placement="left" theme="light" :transfer="true" transfer-class-name="set_bigimg_style">
                        <img class="img_styles" :src="setImgPath(productGoods.pictureUrl)" @error="setErrorImg($event)" alt=""/>
                        <template #content>
                          <img :ref="'thumbnailImg'" @load="handlerOnloadImg('thumbnailImg')"
                            :src="setImgPath(productGoods.pictureUrl)" @error="setErrorImg($event)" alt=""/>
                        </template>
                      </Tooltip>
                    </div>
                  </Col>
                  <Col span="10">
                    <div style="fontSize:22px;marginTop:20px;">
                      SKU：<span style="color:#008000;">{{
                        productGoods.sku
                      }}</span>
                    </div>
                    <div style="fontSize:22px;marginTop:20px;">
                      {{ $t('key1001705') }}<span style="color:#008000;">{{ productGoods.title }}</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <!-- 货品异常警告 -->
            <div class="warnBox" v-if="!showProductGoods && sku !== null && sku !== ''">
              <div style="height:25px;background:#dee6ee;padding:5px;border-bottom:1px solid #c5d2e1;">
                {{ $t('key1003674') }}
              </div>
              <div style="width:94%;height:200px;background:#faeed8;border:1px solid #dcddda;marginTop:30px;marginLeft:3%;padding:15px;">
                <h2>
                  {{ $t('key1003669').replace('{sku}', sku) }} </h2>
                <div style="marginTop:10px;fontSize:14px;">
                  <div style="marginBottom:5px;">
                    {{ $t('key1003675') }}
                  </div>
                  <span>{{ $t('key1003676') }}</span><br/> <span>{{ $t('key1003677') }}</span>
                  <div style="marginTop:5px;">
                    {{ $t('key1003678') }}
                  </div>
                </div>
              </div>
              <div class="warnBtn">
                {{ $t('key1003679') }}
              </div>
            </div>
          </div>
        </Col>
        <!-- 拣货单及出库单 -->
        <Col span="8">
          <Tabs :value="tabName" type="card" style="margin:15px 10px 0 10px;" @on-click="changeTab">
            <TabPane name="pickTab" style="height:485px;background-color: rgba(215, 215, 215, 1);" :label="pickTab">
              <h2 style="marginTop:20px;marginBottom:10px;">
                {{ $t('key1003680') }} <span style="color:#0000FF;">{{ showNo }}</span>
              </h2>
              <Table border height="300" :columns="pickGoodsColumn" :data="pickGoodsData"></Table>
            </TabPane>
            <TabPane
              ref="packTab"
              name="packTab"
              style="height:485px;background-color: rgba(215, 215, 215, 1);"
              :label="packTab"
              :disabled="packDisabled">
              <h2 style="marginTop:20px;marginBottom:10px;">
                {{ $t('key1003681') }} <span style="color:#0000FF;">{{ curBox.boxData.packageCode }}</span>
              </h2>
              <div :style="curBox.color" class="showBoxNo">
              </div>
              <Table
                highlight-row
                border
                height="300"
                :columns="curPackGoodsTabColumn"
                :data="curBox.boxData.sortingPackageGoodsResult"
                :row-class-name="rowClassName"></Table>
              <Button style="marginTop:30px;marginLeft:20px;" @click="restPackage">{{ $t('key1003682') }} </Button>
              <Button style="marginLeft:30px;marginTop:30px;" @click="removePackage">{{ $t('key1003683') }} </Button>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
    <!-- 篮子(包裹) -->
    <div class="pickingGoods">
      <Row>
        <!-- 左 -->
        <Col span="8">
          <template v-for="(item, index) in leftBoxs" v-if="leftBoxsStatus">
            <div class="pickBox" :style="item.color" @click="showLeftBox(index)" :key="index">
              <div style="border-top: 15px solid #ed4014;border-right: 15px solid transparent;float:left;"></div>
              <div style="text-align:center;marginTop:5px;">
                {{ item.boxNo }}
              </div>
              <div style="text-align:center;marginTop:3px;">
                {{ item.boxData ? item.boxData.packageCode : '' }}
              </div>
            </div>
            <div v-if="(index + 1) % leftCol === 0" style="float: none;height: 62px;" :key="item.boxNo"></div>
          </template>
        </Col>
        <!-- 中 -->
        <Col span="8" style="borderLeft:5px solid #528ed5;paddingLeft:10px;">
          <template v-for="(item, index) in midBoxs" v-if="midBoxsStatus">
            <div class="pickBox" :style="item.color" @click="showMidBox(index)" :key="index">
              <div style="border-top: 15px solid #ff9900;border-right: 15px solid transparent;float:left;"></div>
              <div style="text-align:center;marginTop:5px;">
                {{ item.boxNo }}
              </div>
              <div style="text-align:center;marginTop:3px;">
                {{ item.boxData ? item.boxData.packageCode : '' }}
              </div>
            </div>
            <div v-if="(index + 1) % midCol === 0" style="float: none;height: 62px;" :key="item.boxNo"></div>
          </template>
        </Col>
        <!-- 右 -->
        <Col span="8" style="borderLeft:5px solid #528ed5;paddingLeft:10px;">
          <template v-for="(item, index) in rigBoxs" v-if="rigBoxsStatus">
            <div class="pickBox" :style="item.color" @click="showRigBox(index)" :key="index">
              <div style="border-top: 15px solid #2db7f5;border-right: 15px solid transparent;float:left;"></div>
              <div style="text-align:center;marginTop:5px;">
                {{ item.boxNo }}
              </div>
              <div style="text-align:center;marginTop:3px;">
                {{ item.boxData ? item.boxData.packageCode : '' }}
              </div>
            </div>
            <div v-if="(index + 1) % rigCol === 0" style="float: none;height: 62px;" :key="item.boxNo"></div>
          </template>
        </Col>
      </Row>
    </div>
    <!-- 全部分拣完成模态框 -->
    <!-- <Modal v-model="isFinish" v-if="finModalStatus" width="500" :styles="{top: '200px'}" :mask-closable="false" :closable="false">
      <div slot="header" style="color:#fff">
        <h3>全部分拣完成</h3>
      </div>
      <div>
        <Row>
          <Col span="6">
            <div><Icon type="information-circled" color="#ffcc00" style="font-size:50px;marginLeft:20px;"></Icon></div>
          </Col>
          <Col>
            <span style="font-size:15px;font-weight:bold">{{ '拣货单号：{pickingGoodsNo} 已经分拣完成，点击【结束本次分拣】结束本次作业'.replace('{pickingGoodsNo}', pickingGoodsNo) }}</span>
            <Input v-model.trim="newPickingGoodsNo" style="width:300px;marginTop:20px;" placeholder="扫描/录入拣货单号开始多品分拣" @on-enter="newPickStart"></Input>
          </Col>
        </Row>
      </div>
      <div slot="footer">
        <Button @click="sortingEnd" style="marginRight:200px;">结束分拣</Button>
      </div>
    </Modal> -->
    <!-- 结束分拣有异常 -->
    <Modal
      v-model="abnormal"
      v-if="abnormalStatus"
      width="1120"
      :styles="{ top: '100px' }"
      :mask-closable="false"
      :title="$t('key1005888')"
      :closable="false">
      <div style="height:60px;border:1px solid #ccc;background:#ffffcc;lineHeight:60px;font-size:16px;">
        <div>
          <Icon
            type="md-information-circle"
            color="#ffcc00"
            style="font-size:40px;marginLeft:20px;marginRight:20px;vertical-align:middle;"></Icon>
          {{ $t('key1003804').replace('{pickingGoodsNo}', showNo) }}:
        </div>
      </div>
      <div style="border:1px solid #ccc;background:#d6e9f2;marginTop:20px;">
        <Row>
          <Col span="15" style="marginLeft:10px;">
            <Row>
              <Col span="20">
                <span style="color:#ff0000;">{{ $t('key1003805') }}</span>
              </Col>
              <Col span="4">
                <span
                  style="cursor:pointer;" @click="saveAsExcel(0)">{{ $t('key1003716') }}</span>
              </Col>
            </Row>
            <Table ref="nopickTable" border height="300" :columns="nopickDataColumn" :data="nopickData"></Table>
            <Button type="primary" class="ml10" @click="PrintDistributionList">{{ $t('key1004148') }} </Button>
            <div style="marginTop:20px;fontSize:14px;">
              <div style="marginBottom:5px;">
                {{ $t('key1003675') }}
              </div>
              <span>{{ $t('key1003806') }}</span><br/> <span>{{ $t('key1003807') }}</span><br/>
              <span>{{ $t('key1003808') }}</span>
            </div>
          </Col>
          <Col span="8" style="marginLeft:20px;">
            <span style="cursor:pointer;" @click="saveAsExcel(1)">{{ $t('key1003716') }}</span>
            <Table
              ref="nopickGoodsTable"
              border
              height="300"
              :columns="nopickGoodsColumn"
              :data="nopickGoodsData"></Table>
            <div style="marginTop:40px;fontSize:14px;">
              <span>{{ $t('key1003809') }}</span>
            </div>
          </Col>
        </Row>
      </div>
      <template #footer>
        <Row>
          <Col span="18">
            <div style="width:600px;height:60px;border:1px solid #ccc;background:#ffffcc;text-align:left;padding:5px;">
              <div style="marginTop:10px;">
                {{ $t('key1003810') }} {{ $t('key1003811') }}<span
                style="color:#0000FF;cursor:pointer;" @click="cancelPick">{{ $t('key1003812') }}</span>{{ $t('key1003813') }}
              </div>
            </div>
          </Col>
          <Col span="6" style="marginTop:15px;">
            <Button type="error" @click="endsorting">{{ $t('key1003067') }} </Button>
            <Button type="primary" @click="backPick">{{ $t('key1003814') }} </Button>
          </Col>
        </Row>
      </template>
    </Modal>
    <!--订单取消提示-->
    <orderCancelPromptModal ref="orderCancelPrompt" @updateData="initFun"></orderCancelPromptModal>
  </div>
</template>

<script>
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from '@/components/mixin/common_mixin';
import sortingInfo_mixin from "@/components/mixin/sortingInfo_mixin";
import {getErpconfig} from '@/utils/user';
import orderCancelPromptModal from "@/components/common/orderCancelPromptModal";
import {downloadControl, supplierVerifyPicking, qs} from "@/utils/common";

export default {
  mixins: [Mixin, sortingInfo_mixin],
  data() {
    return {
      labelTypeText: '',
      breadcrumbList: [
        {name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000018')},
        {name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003685')},
        {name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001823'), path: '/wms/sorting'},
        {name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003686')}
      ],
      clearTime: null,
      filenodeViewTargetUrl: localStorage.getItem('imgUrlPrefix'),
      pickingGoodsNo: null, // 当前拣货单编号
      pickingGoodsId: null, // 当前拣货单id
      pickingGoodsNoStatus: false, // 开启新的拣货单重置数据
      sku: null, // 输入sku
      productGoods: {
        // 当前货品展示
        sku: null,
        title: null,
        pictureUrl: null
      },
      showProductGoods: false, // 控制是否显示当前货品信息（无效时用到）
      tabName: 'pickTab', // 控制标签页显示
      pickTab: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003667'),
      packTab: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002459'),
      packDisabled: true, // 控制出库单标签页的显示隐藏
      pickDataNum: 0, // 已完成扫描的货品数量
      leftBoxs: [], // 左侧货架
      midBoxs: [], // 中间货架
      rigBoxs: [], // 右侧货架
      leftRow: null,
      leftCol: null,
      midRow: null,
      midCol: null,
      rigRow: null,
      rigCol: null,
      isFinishStatus: false, // 全部分拣完成提示信息
      isFinish: false, // 分拣完成模态框
      finModalStatus: false, // 是否加载分拣完成模态框
      newPickingGoodsNo: null, // 开启新的拣货单
      curBox: {
        // 当前篮子
        boxData: {}
      },
      packageData: [], // 出库单列表数据
      rowClassIndex: null, // 记录当前高亮行下标
      pickGoodsData: [], // 拣货单货品数据
      pickGoodsColumn: [
        {
          title: 'NO',
          width: 60,
          align: 'center',
          fixed: 'left',
          type: 'index',
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003688'),
          key: '',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('div', {
                style: {
                  color: '#008000'
                }
              }, params.row.sku), h('div', params.row.title), h('div', params.row.variations)
            ]);
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003689'), // 总货品数量
          key: 'allQuantity',
          align: 'center'
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005889'),
          align: 'center',
          render(h, params) {
            let voidQuantity = params.row.allQuantity - params.row.realQuantity;
            return h('span', {
              style: {
                color: voidQuantity > 0 ? 'red' : '#515a6e'
              }
            }, voidQuantity);
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003690'), // 已分拣货品数量
          key: '',
          align: 'center',
          render: (h, params) => {
            return h('div', params.row.haveNum);
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003055'), // 还需
          key: '',
          align: 'center',
          render: (h, params) => {
            let num = params.row.quantity - params.row.haveNum;
            if (num) {
              return h('div', {
                style: {
                  color: '#FF0000'
                }
              }, num);
            } else {
              return h('div', {
                style: {
                  color: '#008000'
                }
              }, aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003056'));
            }
          }
        }
      ],
      curPackGoodsTabColumn: [
        {
          title: 'NO',
          width: 60,
          align: 'center',
          fixed: 'left',
          type: 'index',
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003688'),
          key: '',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('div', {
                style: {
                  color: '#008000'
                }
              }, params.row.sku), h('div', params.row.title), h('div', params.row.variations)
            ]);
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003829'),
          key: 'quantity',
          align: 'center'
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003830'),
          key: 'haveSkuNum',
          align: 'center'
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003055'),
          key: '',
          align: 'center',
          render: (h, params) => {
            let num = params.row.quantity - params.row.haveSkuNum;
            if (num) {
              return h('div', {
                style: {
                  color: '#FF0000'
                }
              }, num);
            } else {
              return h('div', {
                style: {
                  color: '#008000'
                }
              }, aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003056'));
            }
          }
        }
      ],
      abnormal: false, // 分拣结束有异常模态框
      abnormalStatus: false, // 是否显示分拣结束异常模态框
      nopickData: [], // 未完成所有货品分拣的包裹数据列表
      nopickDataColumn: [
        {
          title: 'NO',
          width: 60,
          align: 'center',
          fixed: 'left',
          type: 'index',
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003815'),
          key: 'boxNo',
          align: 'center',
          render: (h, params) => {
            if (params.row.noBoxNo) {
              return h('div', aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003816'));
            } else {
              return h('div', params.row.boxNo);
            }
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000879'),
          key: 'packageCode',
          align: 'center',
          render: (h, params) => {
            return h('span', {
              style: {
                color: '#1e5494'
              }
            }, params.row.packageCode);
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005890'),
          key: '',
          align: 'center',
          render: (h, params) => {
            if (params.row.noBoxNo) {
              let list = params.row.sortingPackageGoodsResult;
              let pos = [];
              list.forEach((n, i) => {
                let item = h('div', [h('div', n.sku + '(' + n.haveSkuNum + '/' + (n.quantity - n.haveSkuNum) + ')')]);
                pos.push(item);
              });
              return h('div', pos);
            } else {
              let list = params.row.boxData.sortingPackageGoodsResult;
              let pos = [];
              if (list) {
                list.forEach((n, i) => {
                  if (n.haveSkuNum < n.quantity) {
                    let item = h('div', [h('div', n.sku + '(' + n.haveSkuNum + '/' + (n.quantity - n.haveSkuNum) + ')')]);
                    pos.push(item);
                  }
                });
              }
              return h('div', pos);
            }
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003818'),
          key: '',
          align: 'center',
          render: (h, params) => {
            if (params.row.noBoxNo) {
              return h('div', aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003819'));
            } else {
              return h('div', aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003820'));
            }
          }
        }
      ],
      nopickGoodsData: [], // 为完成分拣货品列表
      nopickGoodsColumn: [
        {
          title: 'NO',
          width: 60,
          align: 'center',
          fixed: 'left',
          type: 'index',
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003744'),
          key: 'sku',
          align: 'center',
          render: (h, params) => {
            return h('span', {
              style: {
                color: '#008000'
              }
            }, params.row.sku);
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003821'),
          key: 'haveNum',
          align: 'center'
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003822'),
          key: '',
          align: 'center',
          render: (h, params) => {
            return h('div', params.row.quantity - params.row.haveNum);
          }
        }
      ],
      greenLightNum: null, // 记录已经完成分拣的篮子编号
      greenLightStatus: false, // 通过状态判断避免重复调用方法
      showNo: null, // 展示的拣货单号
      leftBoxsStatus: true, // 拣货架状态
      midBoxsStatus: true,
      rigBoxsStatus: true,
      printUrl: '', // 打印地址
      index: 0,
      isPrint: false // 是否需要打印
    };
  },
  methods: {
    PrintDistributionList() {
      /**
       * 打印配货清单
       * */
      let v = this;
      let obj = {
        packageCodeList: v.nopickData.map(i => i.packageCode),
        warehouseId: v.getWarehouseId()
      };
      v.$Spin.show();
      v.axios.post(api.post_packageInfo_queryDeliveryListPath, obj).then(response => {
        v.$Spin.hide();
        if (response.data.code === 0) {
          let data = response.data.datas;
          data.every((n, i) => {
            v.commonPrint(n.deliveryListLabelPath, n.packageCode);
            return true;
          });
        }
      });
    },
    clearSku() {
      this.sku = this.$refs.curSku.$refs.input.value;
      this.$refs.curSku.$refs.input.value = '';
    },
    skuStart() {
      // 输入sku开始分拣
      let v = this;
      console.log('输入sku开始分拣:skuStart');
      v.$nextTick(() => {
        v.$refs.curSku.$refs.input.value = '';
      });
      if (this.state === 2) {
        // 先调用方法关掉所有灯
        light.closeAll();
      }
      if (v.greenLightNum !== null && v.greenLightStatus) {
        light.openGreen(v.greenLightNum); // 绿灯常亮 当前篮子已装满
        v.greenLightStatus = false;
      }

      let validObj = v.hasBox();
      let arr = [
        {
          pickGoodsData: v.pickGoodsData
        },
        {
          packageData: v.packageData
        },
        {
          validObj: validObj
        },
        {
          pickDataNum: v.pickDataNum
        },
        {
          sku: v.sku
        },
        {
          productGoods: v.productGoods
        },
        {
          curBox: v.curBox
        },
        {
          leftBoxs: v.leftBoxs
        },
        {
          midBoxs: v.midBoxs
        },
        {
          rigBoxs: v.rigBoxs
        }
      ];
      console.table(arr);
      if (!validObj.isPickGoods) {
        v.$Message.info(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003693'));
        v.showProductGoods = false;
        return;
      }

      if (!validObj.hasBox) {
        v.$Message.info(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003694'));
        return;
      }
      v.showProductGoods = false;
      v.pickGoodsData.forEach((n, i) => {
        if (v.sku === n.sku || v.sku === n.upc) {
          if (n.quantity === n.haveNum) {
            // 判断拣货单中该sku是否已完成分拣
            return false;
          }
          if (n.quantity === n.haveNum + 1) {
            // 记录当前拣货单完成分拣的情况
            console.log('SKU:' + v.sku + '分拣完成');
            v.pickDataNum += 1;
            n.pickFinishStatus = true;
            if (v.pickDataNum === v.pickGoodsData.length) {
              // 全部分拣完毕
              // v.isFinish = true;
              // v.finModalStatus = true;
              v.isFinishStatus = true; // 显示全部完成分拣提示信息
            }
          }
          v.showProductGoods = true;
          v.tabName = 'packTab'; // 若sku有效，显示出库单
          v.packDisabled = false; // 关掉disabled,显示出库单
          v.productGoods = n;
          n.haveNum += 1;
          v.selectBox();
        }
      });
      // 高亮当前行
      let data = v.curBox.boxData.sortingPackageGoodsResult;
      if (data) {
        data.forEach((m, t) => {
          if (m.sku === v.sku || m.upc === v.sku) {
            v.rowClassIndex = t;
          }
        });
      }
    },
    rowClassName(row, index) {
      let v = this;
      if (index === v.rowClassIndex) {
        return 'demo-table-info-row';
      } else {
        return '';
      }
    },
    packageCodeHasSku() {
      // 检查是否有没有分配完的出库单
      let hasTrue = false;
      let v = this;
      [...v.leftBoxs, ...v.midBoxs, ...v.rigBoxs].forEach(i => {
        if (i.boxData && i.boxData.sortingPackageGoodsResult) {
          i.boxData.sortingPackageGoodsResult.forEach(j => {
            if (j.sku === v.sku && j.haveSkuNum < j.quantity) {
              hasTrue = true;
            }
          });
        }
      });
      return hasTrue;
    },
    hasBox() {
      /**
       * @Description:判断是否有可用拣货篮
       * @author gan
       * @date: 2020/11/13 15:20
       * @params:
       */
      let hasBox = true;
      let isPickGoods = false; // 是当前拣货单商品
      let firstJoin = false; // 是否第一次拣货
      let v = this;
      v.pickGoodsData.forEach((n, i) => {
        if (v.sku === n.sku || v.sku === n.upc) {
          if (n.haveNum === 0) {
            firstJoin = true;
          }
          isPickGoods = true;
        }
      });
      if (isPickGoods) {
        // 是当前拣货单商品
        // 先判断是否第一次加入
        if (firstJoin) {
          // 判断是否存在在正在拣货的篮子
          if (v.packageCodeHasSku()) {
            hasBox = true;
          } else {
            // 判断是否有空篮子
            hasBox = v.leftBoxs.some(i => i.boxData === null) ||
              v.midBoxs.some(i => i.boxData === null) ||
              v.rigBoxs.some(i => i.boxData === null);
          }
        }
      }
      return {
        hasBox: hasBox,
        isPickGoods: isPickGoods
      };
    },
    selectBox() {
      // 选择符合条件的箱子
      if (!this.findBox(this.leftBoxs)) {
        if (!this.findBox(this.midBoxs)) {
          this.findBox(this.rigBoxs);
        }
      }
    },
    findBox(boxList) {
      // 根据指定的箱子集合查找有效的箱子
      let v = this;
      console.log('找篮子:findBox');
      console.log('====boxList======', boxList);
      if (boxList.length) {
        let isF = false;
        for (let i = 0; i < boxList.length; i++) {
          let box = boxList[i];
          if (box.boxData && !box.boxIsFinish) {
            let sortingPackageGoodsResult = box.boxData.sortingPackageGoodsResult;
            for (let m = 0; m < sortingPackageGoodsResult.length; m++) {
              let k = sortingPackageGoodsResult[m];
              if ((v.sku === k.sku || v.sku === k.upc) && k.haveSkuNum < k.quantity) {
                // 当前篮子需要这个sku
                console.log('box.boxData && !box.boxIsFinish');
                console.log(k);
                isF = true;
                k.haveSkuNum += 1;
                v.curBox = box;
                if (k.quantity === k.haveSkuNum) {
                  console.log('box.boxData && !box.boxIsFinish && k.quantity === k.haveSkuNum');
                  box.packDataNum++;
                  console.log(box.packDataNum);
                  console.log(v.curBox);
                  if (sortingPackageGoodsResult.length === box.packDataNum) {
                    console.log('box.boxData && !box.boxIsFinish && k.quantity === k.haveSkuNum && sortingPackageGoodsResult.length === box.packDataNum');
                    box.color = 'background:#00B150;cursor:pointer;'; // 绿色
                    box.boxIsFinish = true; // 当前篮子已完成分拣
                    if (v.isPrint) {
                      v.print(box.boxData.labelPath, '1', box.boxData);
                    }
                    if (this.state === 2) {
                      light.flashGreen(box.boxNo); // 绿灯闪烁 当前货品是这个篮子的最后一个
                      v.greenLightNum = box.boxNo;
                      v.greenLightStatus = true;
                    }
                  }
                }
                return true;
              }
            }
          }
        }
        console.log('++++++++isF++++=====', isF);
        if (isF) return;
        for (let i = 0; i < boxList.length; i++) {
          let box = boxList[i];
          if (!box.boxData) {
            // 判断篮子是否为空
            box.boxData = v.getPackageData(box);
            // 已出库处理
            box.boxData.sortingPackageGoodsResult.forEach(p => {
              if (p.quantity === 0) {
                // 都已出库
                box.packDataNum++;
              }
            });
            box.color = 'background:#9BC053;cursor:pointer;';
            if (this.state === 2) {
              light.flashRed(box.boxNo); // 红灯闪烁 篮子刚分配且当前货品不是最后一个
            }

            // 未完成分拣
            let sortingPackageGoodsResult = box.boxData.sortingPackageGoodsResult;
            for (let m = 0; m < sortingPackageGoodsResult.length; m++) {
              let k = sortingPackageGoodsResult[m];
              if ((v.sku === k.sku || v.sku === k.upc) && k.haveSkuNum < k.quantity) {
                // 当前篮子需要这个sku
                k.haveSkuNum += 1;
                v.curBox = box;
                if (k.quantity === k.haveSkuNum) {
                  box.packDataNum++;
                  if (sortingPackageGoodsResult.length === box.packDataNum) {
                    box.color = 'background:#00B150;cursor:pointer;';
                    box.boxIsFinish = true; // 当前篮子已完成分拣
                    if (v.isPrint) {
                      v.print(box.boxData.labelPath, '1', box.boxData);
                    }
                    if (this.state === 2) {
                      light.flashGreen(box.boxNo); // 绿灯闪烁 当前货品是这个篮子的最后一个
                      v.greenLightNum = box.boxNo;
                      v.greenLightStatus = true;
                    }
                  }
                }
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    getPackageData(box) {
      // 获取当前sku的包裹信息
      let v = this;
      console.log('Function getPackageData');
      for (let i = 0; i < v.packageData.length; i++) {
        for (let j = 0; j < v.packageData[i].sortingPackageGoodsResult.length; j++) {
          if (v.sku === v.packageData[i].sortingPackageGoodsResult[j].sku || v.sku === v.packageData[i].sortingPackageGoodsResult[j].upc) {
            let temp = JSON.parse(JSON.stringify(v.packageData[i]));
            console.log(temp);
            box.delPackData = v.packageData.splice(i, 1); // 存起来方便在重置包裹时使用
            console.log(temp);
            console.log('End');
            return temp;
          }
        }
      }
      return null;
    },
    getPickingList(obj) {
      // 获取拣货单列表
      let v = this;
      let pos = [];
      if (v.pickingGoodsId) {
        v.pickDataNum = 0;
        v.axios.get(api.get_packageGoods_queryByPickingGoodsId + v.pickingGoodsId).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            data.sortingPackageGoodsResult.forEach((n, i) => {
              n.haveNum = 0; // 拣货单已分拣货品数量
              n.pickFinishStatus = false; // 拣货单分拣完成状态 false未完成 true已完成
              v.packageData.forEach(j => {
                j.sortingPackageGoodsResult.forEach(k => {
                  if (n.sku === k.sku) {
                    if (n.realQuantity) {
                      n.realQuantity += k.quantity; //  目的是统计已出库数量
                    } else {
                      n.realQuantity = k.quantity;
                    }
                  }
                });
              });
              n.allQuantity = n.quantity;
              n.quantity = n.realQuantity;
              if (!n.realQuantity || n.realQuantity === 0) {
                v.pickDataNum += 1;
              }
              pos.push(n);
            });
            v.pickGoodsData = pos;
            v.showNo = data.pickingGoodsNo;
            v.labelTypeList.map((item) => {
              if (item.value === data.labelType) {
                if (item.value === 'null') {
                  v.labelTypeText = '-';
                } else {
                  v.labelTypeText = item.title;
                }
              }
            });
            let pathlist = [];
            if (v.showNo) {
              pathlist.push({
                name: v.showNo
              });
              let arr = [...v.breadcrumbList, ...pathlist];
              v.breadcrumbList = v.uniqueFunc(arr, 'name');
            }

            // 若拣货单内移除取消的出库单后，无其他出库单，则弹窗提示，点击知道了或关闭弹窗则结束分拣，跳转至多品分拣页面
            if (obj.allDelete === 1) {
              if (v.pickGoodsData.length <= 0) {
                v.$Modal.info({
                  title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003696'),
                  content: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003697'),
                  okText: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000588'),
                  onOk: () => {
                    // v.pickFinish();
                    v.$router.push('/wms/sorting');
                  },
                });
              }
            }
          }
        });
      }
    },
    getPackList() {
      // 获取出库单列表
      let v = this;
      return new Promise(resolve => {
        if (v.pickingGoodsId) {
          v.axios.get(api.get_packageInfo_queryByPickingGoodsId + v.pickingGoodsId).then(response => {
            if (response.data.code === 0) {
              let data = response.data.datas;
              data.forEach((n, i) => {
                n.sortingPackageGoodsResult.forEach((j, k) => {
                  j.haveSkuNum = 0; // 出库单已分拣货品数量
                });
              });
              v.packageData = data;
              if (v.packageData && v.packageData.length > 0) {
                v.printUrl = v.packageData[0].labelPath;
              }
              resolve();
            }
          });
        }
      });
    },
    getPackageSetting() {
      // 获取分拣架设置列表
      let v = this;
      let obj = {
        typeList: ['sorting', 'packing']
      };
      v.axios.post(api.get_packageSetting, obj).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          let leftBoxsStatus = false;
          let midBoxsStatus = false;
          let rigBoxsStatus = false;
          data.forEach((item, i) => {
            if (item.paramKey === 'enableLeftShelfType') {
              if (item.paramValue === 'Y') {
                leftBoxsStatus = true;
              }
            } else if (item.paramKey === 'enableMidShelfType') {
              if (item.paramValue === 'Y') {
                midBoxsStatus = true;
              }
            } else if (item.paramKey === 'enableRightShelfType') {
              if (item.paramValue === 'Y') {
                rigBoxsStatus = true;
              }
            }
          });
          data.forEach((item, i) => {
            let dataItem = item;
            let paramKey = item.paramKey;
            let paramKey1 = item[paramKey];
            if (item.paramKey === 'leftShelfType') {
              if (leftBoxsStatus) {
                v.leftRow = item.paramValue.split('x')[0];
                v.leftCol = item.paramValue.split('x')[1];
              }
            } else if (item.paramKey === 'midShelfType') {
              if (midBoxsStatus) {
                v.midRow = item.paramValue.split('x')[0];
                v.midCol = item.paramValue.split('x')[1];
              }
            } else if (item.paramKey === 'rightShelfType') {
              if (rigBoxsStatus) {
                v.rigRow = item.paramValue.split('x')[0];
                v.rigCol = item.paramValue.split('x')[1];
              }
            } else if (item.paramKey === 'enableLeftShelfType') {
              v.leftBoxsStatus = item.paramValue === 'Y';
            } else if (item.paramKey === 'enableMidShelfType') {
              v.midBoxsStatus = item.paramValue === 'Y';
            } else if (item.paramKey === 'enableRightShelfType') {
              v.rigBoxsStatus = item.paramValue === 'Y';
            } else if (item.paramKey === 'enableSortingPrinted') {
              // 是否需要打印
              v.isPrint = item.paramValue === 'Y';
            } else {
            }
          });
          v.initBox();
        }
      });
    },
    initBox() {
      // 初始化拣货框
      let v = this;
      if (v.leftRow && v.leftCol) {
        for (let i = 1; i <= v.leftRow; i++) {
          for (let j = 1; j <= v.leftCol; j++) {
            let box = {
              boxNo: '1' + i + j,
              boxData: null, // 存储包裹信息
              packDataNum: 0, // 已扫描货品种类数
              color: 'background:white',
              boxIsFinish: false // true已完成  false未完成
            };
            v.leftBoxs.push(box);
          }
        }
      }
      if (v.midRow && v.midCol) {
        for (let n = 1; n <= v.midRow; n++) {
          for (let k = 1; k <= v.midCol; k++) {
            let box = {
              boxNo: '2' + n + k,
              boxData: null, // 存储包裹信息
              packDataNum: 0, // 已扫描货品种类数
              packageCode: null,
              color: 'background:white',
              boxIsFinish: false
            };
            v.midBoxs.push(box);
          }
        }
      }
      if (v.rigRow && v.rigCol) {
        for (let s = 1; s <= v.rigRow; s++) {
          for (let t = 1; t <= v.rigCol; t++) {
            let box = {
              boxNo: '3' + s + t,
              boxData: null, // 存储包裹信息
              packDataNum: 0, // 已扫描货品种类数
              color: 'background:white',
              boxIsFinish: false // 判断篮子是否满了
            };
            v.rigBoxs.push(box);
          }
        }
      }
    },
    print(obj, id, data) {
      let v = this;
      v.printHtmlUrl(obj, id).then(response => {
        if (response.printType === 'cainiao') {
          v.sortingFinishPrint(data);
          return;
        }
        let jsonObj = response;
        if (jsonObj.content) {
          jsonObj.content = encodeURIComponent(jsonObj.content);
        }
        let instance = v.axios.create({
          timeout: 3000,
          headers: {
            'Content-Type': 'multipart/form-data; charset=UTF-8'
          }
        });
        delete v.axios.defaults.headers.post['X-Requested-With'];
        instance.post('http://localhost:10099/print', qs(jsonObj)).then(response => {
          if (response.status === 200) {
            v.sortingFinishPrint(data);
          }
        }).catch(() => {
          let fileName = '/tool/TongtoolPrinter.exe';
          downloadControl(fileName).then(()=> {
            v.tableLoading = false;
          });
        });
      });
    },
    sortingFinishPrint(data) {
      let v = this;
      let params = {
        packageIds: [data.packageId],
        pickingGoodsId: v.pickingGoodsId
      };
      v.axios.put(api.put_wmsPickingGoods_sortingFinishPrint, params).then(res => {
        if (res.data.code === 0) {
          v.$Message.success(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003700'));
        }
      });
    },
    showLeftBox(index) {
      // 点击左侧篮子显示出库单信息
      let v = this;
      if (v.leftBoxs[index].boxData) {
        v.curBox = v.leftBoxs[index];
        v.tabName = 'packTab'; // 显示出库单
      }
    },
    showMidBox(index) {
      // 点击中间篮子显示出库单信息
      let v = this;
      if (v.midBoxs[index].boxData) {
        v.curBox = v.midBoxs[index];
        v.tabName = 'packTab'; // 显示出库单
      }
    },
    showRigBox(index) {
      // 点击右侧篮子显示出库单信息
      let v = this;
      if (v.rigBoxs[index].boxData) {
        v.curBox = v.rigBoxs[index];
        v.tabName = 'packTab'; // 显示出库单
      }
    },
    changeTab(name) {
      // 改变tabName的值
      let v = this;
      if (name === 'pickTab') {
        v.tabName = 'pickTab';
      } else if (name === 'packTab') {
        v.tabName = 'packTab';
      }
    },
    sortingEnd() {
      // 分拣完成 无异常
      let v = this;
      let obj = {
        errorPackageIdList: [], // 异常的出库单id列表
        pickingGoodsId: v.pickingGoodsId
      };
      v.overSorting(obj);
    },
    // 结束分拣 ---> 结束分拣时，校验所含出库单状态，若存在订单已取消状态的出库单，则弹窗提示
    overSorting(obj) {
      let v = this;
      supplierVerifyPicking(api.put_wmsPickingGoods_checkPickingGoods, [v.pickingGoodsNo]).then((data) => {
        if (data.length > 0) {
          v.$refs['orderCancelPrompt'].basicData = data;
          v.$refs['orderCancelPrompt'].orderCancelPromptModal = true;
        } else {
          v.axios.post(api.post_wmsPickingGoods_endSorting, obj).then(response => {
            if (response.data.code === 0) {
              v.$router.push('/wms/sorting');
            }
          });
        }
      });
    },
    pickFinish() {
      // 结束本次分拣
      let v = this;
      let list = []; // 未完成分拣包裹列表
      if (v.packageData.length) {
        // packageData里若是有数据则表示有包裹未分配篮子
        v.packageData.forEach((m, t) => {
          m.noBoxNo = true; // 增加noBoxNo表示该包裹未分配拣货框 true未分配 false已分配
          list.push(m);
        });
      }
      if (v.leftBoxs.length) {
        v.leftBoxs.forEach((v, b) => {
          if (v.boxData) {
            // 先判断篮子是否为空
            if (!v.boxIsFinish) {
              // 判断篮子是否未完成分拣 false未完成 true已完成
              v.noBoxNo = false; // 增加noBoxNo表示该包裹未分配拣货框 true未分配 false已分配
              v.packageId = v.boxData.packageId; // 把id拿到外层，在强制结束用到
              v.packageCode = v.boxData.packageCode; // 把code拿到外层，在异常包裹列表用到
              list.push(v);
              if (this.state === 2) {
                light.openRed(v.boxNo); // 红灯常亮 还有篮子未分配
              }
            }
          }
        });
      } else if (v.midBoxs.length) {
        v.midBoxs.forEach((s, p) => {
          if (s.boxData) {
            // 先判断篮子是否为空
            if (!s.boxIsFinish) {
              // 判断篮子是否未完成分拣 false未完成 true已完成
              s.noBoxNo = false; // 增加noBoxNo表示该包裹未分配拣货框 true未分配 false已分配
              s.packageId = s.boxData.packageId;
              s.packageCode = s.boxData.packageCode;
              list.push(s);
              if (this.state === 2) {
                light.openRed(s.boxNo); // 红灯常亮 还有篮子未分配
              }
            }
          }
        });
      } else if (v.midBoxs.length) {
        v.midBoxs.forEach((n, k) => {
          if (n.boxData) {
            // 先判断篮子是否为空
            if (!n.boxIsFinish) {
              // 判断篮子是否未完成分拣 false未完成 true已完成
              n.noBoxNo = false; // 增加noBoxNo表示该包裹未分配拣货框 true未分配 false已分配
              n.packageId = n.boxData.packageId;
              n.packageCode = n.boxData.packageCode;
              list.push(n);
              if (this.state === 2) {
                light.openRed(n.boxNo); // 红灯常亮 还有篮子未分配
              }
            }
          }
        });
      }
      let pos = []; // 未完成分拣货品列表
      v.pickGoodsData.forEach((n, i) => {
        if (!n.pickFinishStatus) {
          // 未完成分拣
          pos.push(n);
        }
      });
      if (!list.length && !pos.length) {
        // 当这两个数组都没有数据时，表示无异常
        v.sortingEnd();
      } else {
        // 反正则表示有异常数据
        v.abnormal = true;
        v.abnormalStatus = true;
        v.nopickGoodsData = pos;
        v.nopickData = [];
        list.map((item) => {
          if (!item.printTime || item.boxData.printTime === null) {
            v.nopickData.push(item);
          }
        });
      }
    },
    backPick() {
      // 返回继续分拣
      let v = this;
      v.abnormal = false;
    },
    cancelPick() {
      // 撤销本次分拣
      let v = this;
      v.axios.post(api.post_wmsPickingGoods_cancelSorting + v.pickingGoodsId).then(response => {
        if (response.data.code === 0) {
          v.$router.push('/wms/sorting');
        }
      });
    },
    endsorting() {
      // 强制结束 有异常
      let v = this;
      let pos = [];
      let arr = [];
      v.nopickData.forEach((n, i) => {
        pos.push(n.packageId);
        if (n.boxData) {
          n.boxData.sortingPackageGoodsResult.forEach(j => {
            arr.push({
              packageGoodsId: j.packageGoodsId,
              actualPickingNumber: j.haveSkuNum
            });
          });
        } else {
          n.sortingPackageGoodsResult.forEach(j => {
            arr.push({
              packageGoodsId: j.packageGoodsId,
              actualPickingNumber: j.haveSkuNum
            });
          });
        }
      });
      let obj = {
        errorPackageIdList: pos,
        updateActualPickingNumBoList: arr,
        pickingGoodsId: v.pickingGoodsId
      };
      v.overSorting(obj);
    },
    restPackage() {
      // 将该包裹重置为未开始分拣
      let v = this;
      v.sku = null;
      v.$refs.curSku.$refs.input.value = null;
      v.curBox.boxData.sortingPackageGoodsResult.forEach((n, i) => {
        v.pickGoodsData.forEach((m, t) => {
          // m.pickFinishStatus = false; // 拣货单分拣完成状态 false未完成 true已完成
          if (((n.sku === m.sku && n.sku) || (n.upc === m.upc && n.upc)) && n.haveSkuNum > 0) {
            m.haveNum -= n.haveSkuNum;
            if (m.pickFinishStatus) {
              v.pickDataNum -= 1;
            }
            m.pickFinishStatus = false; // 拣货单分拣完成状态 false未完成 true已完成
          }
        });
      });
      v.curBox.boxData = '';
      v.curBox.boxIsFinish = false; // 把篮子的状态标记为未完成
      v.curBox.packDataNum = 0;
      v.packageData.push(v.curBox.delPackData[0]);
      v.tabName = 'pickTab'; // 显示拣货单
      v.packDisabled = true; // 打开disabled,隐藏出库单
      v.curBox.color = 'background:white';
      v.isFinishStatus = false; // 隐藏全部完成分拣提示信息
      /*v.leftBoxs.forEach((j, k) => {
        j.packDataNum = 0;
      });
      v.midBoxs.forEach((s, v) => {
        s.packDataNum = 0;
      });
      v.rigBoxs.forEach((x, y) => {
        x.packDataNum = 0;
      });*/
    },
    isPickFinishStatus() {
      // 移除包裹的时候是否完成拣货状态
      this.pickDataNum = 0;
      this.pickGoodsData.forEach(i => {
        if (i.quantity === i.haveNum) {
          i.pickFinishStatus = true;
          this.pickDataNum++;
        }
      });
    },
    removePackage() {
      // 将该包裹从当前拣货单中移除等待生成新的拣货单
      let v = this;
      let obj = {
        packageId: v.curBox.boxData.packageId,
        pickingGoodsId: v.pickingGoodsId
      };
      v.axios.post(api.post_wmsPickingGoods_excludePackageInfo, obj).then(response => {
        if (response.data.code === 0) {
          v.curBox.boxData.sortingPackageGoodsResult.forEach((n, i) => {
            // 先拿到该包裹货品数据
            v.pickGoodsData.forEach((m, t) => {
              // 再从拣货单中删除对应的数量
              if ((n.sku === m.sku && n.sku) || (n.upc === m.upc && m.upc)) {
                if (m.pickFinishStatus) {
                  v.pickDataNum -= 1;
                  m.pickFinishStatus = false; // 拣货单分拣完成状态 false未完成 true已完成
                }
                if (m.quantity - n.quantity) {
                  // 拣货单中货品数量减去出库单货品数量，若为0则从拣货单中删除
                  m.haveNum -= n.haveSkuNum;
                  m.quantity -= n.quantity;
                } else {
                  v.pickGoodsData.splice(t, 1);
                }
                v.isPickFinishStatus();
              }
            });
          });
          v.sku = null;
          v.$refs.curSku.$refs.input.value = null;
          v.curBox.boxData = '';
          v.curBox.boxIsFinish = false; // 把篮子的状态标记为未完成
          v.tabName = 'pickTab'; // 显示拣货单
          v.packDisabled = true; // 打开disabled,隐藏出库单
          v.curBox.color = 'background:white';
          v.curBox.packDataNum = 0;
        }
      });
    },
    saveAsExcel(val) {
      // 保存为Excel
      let v = this;
      let obj = {};
      let filenodeViewTargetUrl = localStorage.getItem('imgUrlPrefix');
      clearTimeout(v.clearTime);
      if (val) {
        // nopickGoodsTable(右侧表格)
        if (!v.nopickGoodsData.length) {
          v.$Message.error('no data！');
          return false;
        }
        // 组装数据
        let excelContentList = []; // 数据
        let excelTitleList = [// 表头
          {
            title: 'No',
            column: 'no'
          }, {
            title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003744'),
            column: 'goodsTotal'
          }, {
            title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003821'),
            column: 'alreadyPick'
          }, {
            title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003822'),
            column: 'waitPick'
          }
        ];
        let pos = [];
        v.nopickGoodsData.forEach((n, i) => {
          pos.push([
            {
              column: 'no',
              content: i + 1
            }, {
              column: 'goodsTotal',
              content: n.sku
            }, {
              column: 'alreadyPick',
              content: n.haveNum
            }, {
              column: 'waitPick',
              content: n.quantity - n.haveNum
            }
          ]);
        });
        pos.forEach((m, t) => {
          excelContentList.push({
            excelColumnContentList: m
          });
        });
        obj = {
          excelContentList: excelContentList,
          excelTitleList: excelTitleList
        };
        console.log(obj);
      } else {
        // nopickTable(左侧表格)
        if (!v.nopickData.length) {
          v.$Message.error('no data！');
          return false;
        }
        // 组装数据
        let excelContentList = []; // 数据
        let excelTitleList = [// 表头
          {
            title: 'No',
            column: 'no'
          }, {
            title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003815'),
            column: 'pickBox'
          }, {
            title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000131'),
            column: 'pickNo'
          }, {
            title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005890'),
            column: 'alorwaitPick'
          }, {
            title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003818'),
            column: 'abReason'
          }
        ];
        let pos = [];
        let box = [];
        v.nopickData.forEach((n, i) => {
          if (n.noBoxNo) {
            let list = n.sortingPackageGoodsResult;
            list.forEach((n, i) => {
              let item = n.sku + '(' + n.haveSkuNum + '/' + (n.quantity - n.haveSkuNum) + ')';
              box.push(item);
            });
          } else {
            let list = n.boxData.sortingPackageGoodsResult;
            if (list) {
              list.forEach((n, i) => {
                if (n.haveSkuNum < n.quantity) {
                  let item = n.sku + '(' + n.haveSkuNum + '/' + (n.quantity - n.haveSkuNum) + ')';
                  box.push(item);
                }
              });
            }
          }
          pos.push([
            {
              column: 'no',
              content: i + 1
            }, {
              column: 'pickBox',
              content: n.noBoxNo ? aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000161') : n.boxNo
            }, {
              column: 'pickNo',
              content: n.packageCode
            }, {
              column: 'alorwaitPick',
              content: box.toString()
            }, {
              column: 'abReason',
              content: n.noBoxNo ? aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003819') : aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003820')
            }
          ]);
        });
        pos.forEach((m, t) => {
          excelContentList.push({
            excelColumnContentList: m
          });
        });
        obj = {
          excelContentList: excelContentList,
          excelTitleList: excelTitleList
        };
        console.log(obj);
      }
      let newTab = window.open();
      v.axios.post(api.post_packageInfo_exportOrderExcel, obj).then(response => {
        if (response.data.code === 0) {
          // let newTab = window.open();
          newTab.location.href = filenodeViewTargetUrl + response.data.datas;
          v.clearTime = setTimeout(function () {
            newTab.close();
          }, 1000);
        } else {
          newTab.close();
        }
      });
    },
    // 初始化接口数据
    initFun(obj) {
      let v = this;
      v.abnormal = false;
      v.abnormalStatus = false;
      // 获取拣货单号
      let pickingInfos = JSON.parse(localStorage.getItem('pickingInfos'));
      if (pickingInfos) {
        v.pickingGoodsNo = pickingInfos.pickingGoodsNo;
        v.pickingGoodsId = pickingInfos.pickingGoodsId;
      }
      v.getPackageSetting();
      v.getPackList().then(() => {
        v.getPickingList(obj);
        // 进入分拣时，校验所含出库单状态，若存在订单已取消状态的出库单，则弹窗提示
        supplierVerifyPicking(api.put_wmsPickingGoods_checkPickingGoods, [v.pickingGoodsNo]).then((data) => {
          if (data.length > 0) {
            v.$refs['orderCancelPrompt'].basicData = data;
            v.$refs['orderCancelPrompt'].orderCancelPromptModal = true;
          }
        });
      });
      if (!v.$store.state.erpConfig) {
        getErpconfig();
      }
    },
  },
  created() {
    this.initFun();
  },
  watch: {
    pickingGoodsNoStatus(n, o) {
      let v = this;
      if (n) {
        v.getPackageSetting();
        v.getPackList().then(() => {
          v.getPickingList();
        });
        v.pickingGoodsNoStatus = false;
      }
    }
  },
  components: {
    orderCancelPromptModal
  }
};
</script>

<style lang="less" scoped>
.sortingInfo {
  .breadcrumb_box {
    padding: 12px 0 0 15px;
  }
}

.pickHeader {
  width: 99%;
  height: 40px;
  margin: 10px auto;
  line-height: 40px;
  background-color: rgba(247, 150, 70, 1);
}

.skuInput {
  margin-left: 10px;
  margin-top: 15px;
  padding-left: 10px;
  height: 65px;
  line-height: 65px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: rgba(43, 120, 228, 1);
}

.showProductGoods {
  height: 457px;
  margin-top: 10px;
  padding-top: 20px;
  margin-left: 10px;
  background-color: rgba(215, 215, 215, 1);
}

.pickingGoods {
  width: 99%;
  margin: 10px auto;
}

.pickBox {
  float: left;
  width: 18%;
  height: 50px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid #000;
  font-weight: bold;
  margin-right: 2%;
}

.productBox {
  width: 300px;
  height: 170px;
  border: 1px solid #000;
  font-size: 24px;
  text-align: center;
  line-height: 170px;
  font-weight: bold;
}

.productInfo {
  width: 96%;
  height: 200px;
  margin-left: 2%;
  padding: 15px 30px;
  background-color: #fff;

  .img_styles {
    vertical-align: middle;
    width: auto;
    max-width: 277px;
    height: 171px;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 50px;
  }
}

.warnBox {
  width: 80%;
  height: 400px;
  border: 1px solid #717274;
  margin-left: 5%;
  background-color: #fff;
}

.warnBtn {
  width: 200px;
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  margin-left: 40%;
  background-color: #aaa;
}

.showBoxNo {
  width: 80px;
  height: 40px;
  border: 1px solid #000;
  margin-bottom: 10px;
  text-align: center;
  line-height: 40px;
}
</style>
<style>
.ivu-table .demo-table-info-row td {
  background-color: #ebf7ff;
}
</style>
